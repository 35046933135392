<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >商务名称：
              <el-autocomplete
                class="ipt_width project_width"
                v-model="parameter.businessName"
                :fetch-suggestions="businessNameQuerySearch"
                placeholder="请输入或选择商务名称"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete
            ></span>
            <span>
              商务类型：
              <Dictionary
                v-model="parameter.businessType"
                class="ipt_width"
                code="BUSINESS_TYPE"
                placeholder="请选择商务类型"
            /></span>
            <span>
              商务负责人：
              <el-select
                class="ipt_width"
                clearable
                v-model="parameter.principalUserId"
                filterable
                placeholder="请输入或者选择"
              >
                <el-option
                  v-for="item in selectedList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </span>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >

            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
            <el-button
              type="primary"
              icon="el-icon-printer"
              v-if="permission(['ALL_BUSINESS_EXPORT'])"
              @click="exporting()"
              >导出</el-button
            >
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogBusiness = true">商务流程</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="parameter.status" class="fullScreenMainHeader">
            <el-tab-pane label="待分工" name="0"></el-tab-pane>
            <el-tab-pane label="不通过" name="1"></el-tab-pane>
            <el-tab-pane label="已通过" name="2"></el-tab-pane>
          </el-tabs>
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              min-width="320"
              prop="businessName"
              :show-overflow-tooltip="false"
              label="商务名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="businessType"
              sortable
              label="商务类型"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.row.businessType | dict(dictData.businessType) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="contacts"
              label="经办人"
              width="90"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="contactsPhone"
              width="120"
              label="经办人电话"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="principalUserName"
              label="实际商务负责人"
              width="130"
            ></el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable="custom"
              prop="createdDate"
              label="填报时间"
            >
              <template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="110"
              sortable="custom"
              prop="businessStartDate"
              label="商务实际开始时间"
            >
              <template slot-scope="scope">
                {{ scope.row.businessStartDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="status" sortable label="状态" width="160">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.businessStauts) }}
              </template>
            </el-table-column>

            <el-table-column prop="title" align="center" fixed="right" width="100" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="text_Edit_Bgc"
                  v-if="parameter.status != 2"
                  size="small"
                  @click.stop="zhipai(scope.row)"
                  >指派用户</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  v-if="parameter.status == 2"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Dispose
      :isEdit="isEdit"
      :showEditDialog.sync="isShow"
      :Dispose_data="Dispose_data"
      v-if="isShow"
    >
    </Dispose>
    <el-dialog title="商务流程图" :visible.sync="dialogBusiness" width="75%">
      <img src="@/assets/商务流程图.png" alt="商务流程图" width="100%" />
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dispose: () => import('../Trace/Dispose.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        businessName: null,
        businessType: null,
        principalUserId: null,
        status: '0',
      },
      pageSize: 0,
      form: {},
      edit_form: {},
      tableData: [],
      loading: false,
      isShow: false,
      showEditDialog: false,
      Dispose_show: false,
      Dispose_data: {},
      dictData: {
        businessType: [],
        businessStauts: [],
      },
      businessList: [],
      selectedList: [],
      isEdit: 'view',
      dialogBusiness: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      allUserList: state => state.business.allUserList,
    }),
  },
  provide() {
    return {
      re: this.re,
      retu: this.retu,
    }
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()

    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('ALL_BUSINESSMANAGE_TYPE', true)
      .then(res => {
        this.dictData.businessStauts = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.businessExpense
      .businessNameList()
      .then(res => {
        this.businessList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    if (this.allUserList.length <= 0) {
      this.getSelectedList()
    } else {
      this.selectedList = this.allUserList
    }
  },
  watch: {
    'parameter.status': {
      handler: function (newVal) {
        if (newVal) {
          this.parameter.pageNow = 1
          this.getData()
        }
      },
    },
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    businessNameQuerySearch(queryString, cb) {
      let businessList = this.businessList.map(item => {
        return { value: item.businessName }
      })
      let results = queryString ? this.businessNameCreateFilter(queryString) : businessList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    businessNameCreateFilter(queryString) {
      let results = this.businessList.map(item => {
        return { value: item.businessName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.businessName = e.value
      }
      this.$forceUpdate()
    },
    // 获取所有人员
    getSelectedList() {
      this.$api.businessManage
        .principalUserNameListNoPage()
        .then(res => {
          this.selectedList = res.data
          this.$store.commit({
            type: 'business/SET_ALL_USER_LIST',
            allUserList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getData() {
      this.loading = true
      this.$api.businessManage
        .getUserAssignmentList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == null) {
            this.tableData = []
          } else {
            this.tableData = res.data.records
            this.parameter.total = res.data.total
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    zhipai(row) {
      this.Dispose_data = row
      this.isEdit = 'edit'
      this.isShow = true
    },
    detail(row) {
      this.Dispose_data = row
      this.isEdit = 'view'
      this.isShow = true
    },
    re() {
      this.getData()
      this.Dispose_data = {}
      this.Dispose_show = false
      this.isShow = false
    },
    retu() {
      this.showEditDialog = false
    },
    handleReset() {
      this.parameter.pageNow = 1
      this.parameter.businessName = null
      this.parameter.businessType = null
      this.parameter.principalUserId = null
      this.getData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    exporting() {
      this.$api.businessManage
        .businessUserAssignmentExpor(this.parameter)
        .then(res => {
          const date = new Date()
          const year = date.getFullYear().toString()
          const month = (date.getMonth() + 1).toString().padStart(2, '0')
          const day = date.getDate().toString().padStart(2, '0')
          const timestamp = year + month + day
          const fileName = '商务成员明细' + timestamp + '.xlsx'
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(e => {
          console.log(e)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.annex {
  display: flex;
  justify-content: space-between;
  /deep/.el-card {
    width: 50%;
    margin: 0 10px;
    .el-card__header {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .el-table {
      height: calc(100vh - 347px);
    }
  }
}
.colorExplain {
  color: black;
  font-size: 14px;
  span {
    margin-right: 25px;
    i {
      display: inline-block;
      width: 25px;
      height: 10px;
    }
  }
}
</style>
